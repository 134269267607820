<template>
	<li
		:class="['page-list-item', {
			'is-active': data.isActive,
			'has-child': data.hasChild
		}]"
		@click="handleSelectItem"
	>
		<p class="page-list-item-name typo-body-2">
			{{ data.name }}
		</p>
		<router-link
			v-if="!isContactUsPage"
			:to="{
				name: 'PageEdit',
				params: { id: data.id }
			}"
			class="page-list-item-edit-button typo-helper-text"
			@click.native.stop
		>
			Edit
		</router-link>
		<FontAwesomeIcon
			v-if="data.hasChild"
			:icon="['fas', 'chevron-right']"
			class="page-list-item-arrow"
		/>
	</li>
</template>

<script>
import { CONTACT_US_SLUG } from '../enums/pages';

export default {
	name: 'PageListItem',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		isContactUsPage() {
			return this.data.slug === CONTACT_US_SLUG;
		},
	},
	methods: {
		handleSelectItem() {
			if (this.data.hasChild) {
				this.$emit('onSelect', this.data.id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-list-item {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		padding: rem(6) rem(26) rem(6) rem(12);
		background-color: $color-white;
		cursor: move;
		border-radius: 4px;
		transition: $transition-duration background-color;

		// when active
		&.is-active {
			background-color: $color-primary-25;
		}

		// when sorting
		&.is-placeholder-item {
			opacity: 0.5;
		}

		// .page-list-item-name
		&-name {
			@include ellipsisOneline;

			flex: 1;
			margin: 0 rem(16) 0 0;
		}

		// .page-list-item-edit-button
		&-edit-button {
			@include link-color($color-black-25, $color-orange);

			min-width: rem(21);
		}

		// .page-list-item-arrow
		&-arrow {
			position: absolute;
			top: 50%;
			right: 4px;
			width: rem(18);
			height: rem(12);
			margin-top: rem(-6); // height/2
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-black-25;
		}
	}
</style>
