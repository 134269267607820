<template>
	<div>
		<p class="page-list-label typo-label">
			{{ label }}
		</p>
		<draggable
			:value="dummyList"
			:disabled="!isDraggable"
			tag="ul"
			ghost-class="is-placeholder-item"
			class="page-list"
			@input="handleUpdateOrder"
		>
			<PageListItem
				v-for="page in dummyList"
				:key="page.id"
				:data="page"
				class="page-list-item"
				@onSelect="hendleSelectItem"
			/>
		</draggable>
	</div>
</template>

<script>
import draggable from 'vuedraggable';
import PageListItem from './PageListItem.vue';

export default {
	name: 'PageList',
	components: {
		draggable,
		PageListItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: '',
		},
		isDraggable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			dummyList: [],
		};
	},
	watch: {
		list: {
			immediate: true,
			handler(list) {
				this.dummyList = list;
			},
		},
	},
	methods: {
		handleUpdateOrder(sortedList) {
			// Update dummy list
			this.dummyList = sortedList;

			// Emit for call API
			const ids = sortedList.map((list) => list.id);
			this.$emit('onDragged', ids);
		},
		handleRevertSorting() {
			this.dummyList = this.list;
		},
		hendleSelectItem(id) {
			this.$emit('onSelect', id);
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-list {
		min-height: rem(36);
		border-radius: 4px;
		background-color: $color-gray-100;
		list-style-type: none;
		padding: 2px;
		margin: 0;

		// .page-list-label
		&-label {
			color: $color-black-45;
			margin-bottom: rem(8);
		}

		// .page-list-item
		&-item {
			margin-bottom: 2px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
</style>
